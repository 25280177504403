import PropTypes from 'prop-types';

import SingleCard from '../FeedCard/SingleCard';
import FeedSocial from '../FeedSocial';
import BFPContent from '../BFPContent';
import Package from '../Package';

/**
 * @todo
 * Upgrade Next.js before using `next/dynamic` instead of static imports for components. Example:
 * `{ post_promo: dynamic(() => import('../FeedCard/SingleCard')) }`
 * This will allow initial server rendering of feed items, with later items lazy loaded to optimize
 * client-side bundles. See the following github issue for context:
 * https://github.com/vercel/next.js/pull/64294
 */
export const COMPONENT_MAP = {
  bfp_content: BFPContent,
  post_promo: SingleCard,
  social_embed: FeedSocial,
  package: Package,
};

/**
 * @param {string} objectType - The type of content object to render.
 * @param {...object} props - The rest of the props to pass to the component.
 */
const ContentComponent = ({objectType, ...props }) => {
  const Component = COMPONENT_MAP[objectType];
  return Component ? <Component {...props} /> : null;
};

ContentComponent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  contentObjectId: PropTypes.number,
  index: PropTypes.number,
  isTrackable: PropTypes.bool,
  objectType: PropTypes.string.isRequired,
  popularLabel: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  showSection: PropTypes.bool,
  trackingData: PropTypes.object,
};

export default ContentComponent;
